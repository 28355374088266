<template>
  <v-container fluid>
    <!-- <v-row
      justify="end"
      no-gutters>
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="warning"
          depressed
          :ripple="false"
          style="width: fit-content;"
          @click="gotoEdit()">
          <v-icon>
            mdi-square-edit-outline
          </v-icon>
          <span>
            แก้ไขข้อมูล
          </span>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row class="form-container rounded-lg secondary--text">
      <v-col cols="12">
        <h3>
          ข้อมูลเวนเดอร์
        </h3>
        <v-divider class="mt-3" />
      </v-col>
      <v-col cols="12">
        <image-uploader
          v-model="images"
          :max-file="1"
          label="โลโก้เวนเดอร์"
          square
          disabled />
      </v-col>
      <v-col
        v-if="role === 'administrator'"
        cols="12">
        <div class="mb-2">
          สถานะการใช้งาน
        </div>
        <div
          class="d-flex flex-row flex-wrap align-center pl-4"
          style="gap: 1rem;">
          <div class="d-flex flex-row flex-wrap align-center">
            <span class="mr-4">
              ปิด
            </span>
            <v-switch
              v-model="formData.status"
              true-value="available"
              false-value="unavailable"
              inset
              hide-details
              class="mt-0 pt-0"
              readonly
              @click="confirmStatusToggle()" />
            <span>
              เปิด
            </span>
          </div>
          <v-btn
            v-if="formData.status === 'inspecting'"
            color="#FFB800"
            dark
            depressed
            :ripple="false"
            @click="confirmDraftToggle()">
            คลิกเพื่อยืนยันการตรวจสอบ
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-else
        cols="12">
        <div class="d-flex flex-row align-center">
          <span>
            สถานะการใช้งาน
          </span>
          <v-icon
            class="ml-2 mr-1"
            :color="checkStatus().color">
            {{ checkStatus().icon }}
          </v-icon>
          <span :class="`${checkStatus().color}--text`">
            {{ checkStatus().text }}
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          เลขที่เวนเดอร์
        </div>
        <v-text-field
          v-model="formData.vendorNo"
          placeholder="000001"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          ชื่อเวนเดอร์
        </div>
        <v-text-field
          v-model="formData.name"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          เลขทะเบียนนิติบุคคล
        </div>
        <v-text-field
          v-model="formData.registrationNo"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          เลขที่ใบอนุญาตประกอบธุรกิจ
        </div>
        <v-text-field
          v-model="formData.businessLicenseNo"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          แนบใบอนุญาตประกอบธุรกิจ
        </div>
        <file-uploader
          v-model="files"
          :max-file="1"
          label="อัปโหลดใบอนุญาต"
          disabled />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          ชื่อ - นามสกุล ผู้ติดต่อ
        </div>
        <v-text-field
          v-model="formData.fullName"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          หมายเลขโทรศัพท์
        </div>
        <v-text-field
          v-model="formData.tel"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          อีเมล
        </div>
        <v-text-field
          v-model="formData.email"
          type="email"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
        </div>
        <v-text-field
          v-model="formData.citizenNo"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col cols="12">
        <div class="mb-1">
          ที่อยู่
        </div>
        <v-text-field
          v-model="formData.address"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="3">
        <div class="mb-1">
          ถนน
        </div>
        <v-text-field
          v-model="formData.road"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="3">
        <div class="mb-1">
          ซอย
        </div>
        <v-text-field
          v-model="formData.alley"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="3">
        <div class="mb-1">
          แขวง / ตำบล
        </div>
        <v-text-field
          v-model="formData.subDistrict"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="3">
        <div class="mb-1">
          เขต / อำเภอ
        </div>
        <v-text-field
          v-model="formData.district"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          จังหวัด
        </div>
        <v-text-field
          v-model="formData.province"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          รหัสไปรษณีย์
        </div>
        <v-text-field
          v-model="formData.postcode"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col cols="12">
        <h3>
          ฟอร์มกรอกข้อมูลช่องทางการเงิน
        </h3>
        <v-divider class="mt-3" />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          ธนาคาร
        </div>
        <v-text-field
          v-model="formData.payment.bankName"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          ชื่อบัญชี
        </div>
        <v-text-field
          v-model="formData.payment.accountName"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <div class="mb-1">
          เลขที่บัญชี
        </div>
        <v-text-field
          v-model="formData.payment.accountNo"
          color="primary"
          outlined
          dense
          hide-details
          readonly />
      </v-col>
    </v-row>
    <v-row
      v-if="role === 'administrator'"
      class="form-container rounded-lg secondary--text mt-6"
      align="center">
      <v-col cols="12">
        <tour-list :vendor-id="vendorId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VendorsProvider from '@/resources/vendors.provider'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import TourList from '../components/TourList.vue'

const VendorsService = new VendorsProvider()

export default {
  components: {
    ImageUploader,
    FileUploader,
    TourList
  },
  data: () => ({
    loading: false,
    formData: {
      vendorNo: '',
      name: '',
      imageUrl: '',
      registrationNo: '',
      businessLicenseNo: '',
      licenseName: '',
      licenseFile: '',
      fullName: '',
      tel: '',
      email: '',
      citizenNo: '',
      address: '',
      road: '',
      alley: '',
      subDistrict: '',
      district: '',
      province: '',
      postcode: '',
      packageAmount: 0,
      orderAmount: 0,
      payment: {
        bankName: '',
        accountName: '',
        accountNo: ''
      },
      status: 'available'
    },
    images: [],
    files: []
  }),
  computed: {
    vendorId () {
      return this.$route.params.id
    },
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    }
  },
  mounted () {
    this.getItemById()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'VendorList' })
    },
    gotoEdit () {
      this.$router.push({
        name: 'VendorEdit',
        params: {
          id: this.vendorId
        }
      })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await VendorsService.getItemById(this.vendorId)

        this.formData = { ...data }
        this.images = data.imageUrl ? [{
          file: null,
          preview: data.imageUrl,
          mediaUrl: data.imageUrl
        }] : []
        this.files = data.licenseFile ? [
          {
            name: data.licenseFileName,
            mediaUrl: data.licenseFile
          }
        ] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onUpdateStatus (id) {
      try {
        this.loading = true

        await VendorsService.updateItemStatusById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไขข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItemById()
      } catch (error) {
        console.error('onUpdateStatus', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onUpdateDraft (id) {
      try {
        this.loading = true

        await VendorsService.updateItemDraftById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไขข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItemById()
      } catch (error) {
        console.error('onUpdateStatus', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    confirmStatusToggle () {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะ${this.formData.status === 'available' ? 'ปิด' : 'เปิด'}การใช้งานเวนเดอร์ "${this.formData.vendorNo}" ใช่หรือไม่?`,
        onConfirm: () => this.onUpdateStatus(this.formData.id)
      })
    },
    confirmDraftToggle () {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันการตรวจสอบเวนเดอร์ "${this.formData.vendorNo}" ใช่หรือไม่?`,
        onConfirm: () => this.onUpdateDraft(this.formData.id)
      })
    },
    checkStatus () {
      if (this.formData.status === 'unavailable') {
        return {
          text: 'ปิดใช้งาน',
          color: 'accent',
          icon: 'mdi-minus-circle'
        }
      }

      if (this.formData.status === 'inspecting') {
        return {
          text: 'รอยืนยันข้อมูล',
          color: 'gold',
          icon: 'mdi-clock-time-four-outline'
        }
      }

      return {
        text: 'เปิดใช้งาน',
        color: 'success',
        icon: 'mdi-circle'
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
